import { readAsArrayBuffer } from './asyncReader.js';
import { fetchFont, getAsset } from './prepareAssets';
import { makePOSTCall, makePUTCall, noop } from './helper.js';

export async function save(pdfFile, pdfFileUrl, objects, name) {
    const PDFLib = await getAsset('PDFLib');
    const download = await getAsset('download');
    const makeTextPDF = await getAsset('makeTextPDF');
    console.log("pdfFile", pdfFile);
    console.log(typeof pdfFile);
    let pdfDoc;
    try {
        // pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
        const existingPdfBytes = await fetch(pdfFileUrl).then(res => res.arrayBuffer())
        pdfDoc = await PDFLib.PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
    } catch (e) {
        console.log('Failed to load PDF.');
        throw e;
    }
    const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
        const pageObjects = objects[pageIndex];
        // 'y' starts from bottom in PDFLib, use this to calculate y
        const pageHeight = page.getHeight();
        const embedProcesses = pageObjects.map(async (object) => {
            if (object.type === 'image') {
                let { file, x, y, width, height } = object;
                let img;
                try {
                    if (file.type === 'image/jpeg') {
                        img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
                    } else {
                        img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
                    }
                    return () =>
                        page.drawImage(img, {
                            x,
                            y: pageHeight - y - height,
                            width,
                            height,
                        });
                } catch (e) {
                    console.log('Failed to embed image.', e);
                    return noop;
                }
            } else if (object.type === 'text') {
                let { x, y, lines, lineHeight, size, fontFamily, width, text } = object;
                const height = size * lineHeight * lines.length;
                const font = await fetchFont(fontFamily);
                return () =>
                    page.drawText(lines.join("\n"), {
                        x: x,
                        y: pageHeight - y - height,
                        size: size,
                        // font: font,
                        color: PDFLib.rgb(1, 0, 0)
                    });
            } else if (object.type === 'drawing') {
                let { x, y, path, scale } = object;
                const {
                    pushGraphicsState,
                    setLineCap,
                    popGraphicsState,
                    setLineJoin,
                    LineCapStyle,
                    LineJoinStyle,
                } = PDFLib;
                return () => {
                    page.pushOperators(
                        pushGraphicsState(),
                        setLineCap(LineCapStyle.Round),
                        setLineJoin(LineJoinStyle.Round),
                    );
                    page.drawSvgPath(path, {
                        borderWidth: 2,
                        scale,
                        x,
                        y: pageHeight - y,
                        borderColor: PDFLib.rgb(1, 0, 0),
                    });
                    page.pushOperators(popGraphicsState());
                };
            }
        });
        // embed objects in order
        const drawProcesses = await Promise.all(embedProcesses);
        drawProcesses.forEach((p) => p());
    });
    await Promise.all(pagesProcesses);
    try {
        const pdfBytes = await pdfDoc.save();
        console.log("pdfBytes", pdfBytes);

        let blob = new Blob([pdfBytes], { type: "application/pdf" });
        let newBody = new FormData();
        newBody.append("fileData", blob);

        let token = new URL(window.location.href).searchParams.get('t');
        let answerId = new URL(window.location.href).searchParams.get('aId');
        let evaluationId = new URL(window.location.href).searchParams.get('eId');
        let headers = {
            "Authorization": "Bearer " + token
        };
        let imageUploadUrl = 'https://api.convertias.com/api/upload/single/evaluatedPDFUrl';
        let evaluationUpdateUrl = 'https://api.convertias.com/api/answers/' + answerId + '/evaluations/' + evaluationId;

        if (!(token && answerId && evaluationId)) {
            alert("Required data not found");
        } else {
            makePOSTCall(imageUploadUrl, newBody, headers).then(response => {
                if (response.data['success']) {
                    if (response.data.results.fileUrl) {
                        makePUTCall(evaluationUpdateUrl, { "evaluatedPDFUrl": response.data.results.fileUrl }, headers).then(response => {
                            console.log("response", response);
                            if (response.data['success']) {
                                console.log("response.data", response.data);
                                alert("Successfully submitted evaluation. Please close this dialog.");
                            }
                        }).catch((err) => {
                            console.log("err", err);
                        });
                    }
                }
            }).catch((err) => {
                console.log("err", err);
            });
        }

        // download(pdfBytes, name, 'application/pdf');
    } catch (e) {
        console.log('Failed to save PDF.');
        throw e;
    }
}
