<script>
  import { createEventDispatcher } from "svelte";
  import { pannable } from "./utils/pannable.js";

  const dispatch = createEventDispatcher();
  let canvas;
  let x = 0;
  let y = 0;
  let path = "";
  let minX = Infinity;
  let maxX = 0;
  let minY = Infinity;
  let maxY = 0;
  let paths = [];
  let drawing = false;
  let prevPathsForScroll;
  export let selectedPageIndex;
  export let pdfWidth;
  export let pdfHeight;
  let margin = (window.innerWidth - pdfWidth) / 2;
  window.onscroll = function () {
    if (
      JSON.stringify(prevPathsForScroll) !== JSON.stringify(paths) ||
      paths.length === 0
    ) {
      prevPathsForScroll = paths;
      finish();
    }
  };
  function finish() {
    if (!paths.length) cancel();
    const dx = -(minX - 10);
    const dy = -(minY - 10);
    const width = maxX - minX + 20;
    const height = maxY - minY + 20;
    dispatch("finish", {
      x: minX - 10,
      y: minY - 10,
      originWidth: width,
      originHeight: height,
      path: paths.reduce((acc, cur) => {
        return acc + cur[0] + (cur[1] + dx) + "," + (cur[2] + dy);
      }, ""),
    });
  }
  function cancel() {
    dispatch("cancel");
  }
  function handlePanStart(event) {
    if (event.detail.target !== canvas) {
      return (drawing = false);
    }
    drawing = true;
    x = event.detail.x - margin;
    y =
      event.detail.y -
      document
        .getElementById("pdfpage" + selectedPageIndex)
        .getBoundingClientRect().top;
    minX = Math.min(minX, x);
    maxX = Math.max(maxX, x);
    minY = Math.min(minY, y);
    maxY = Math.max(maxY, y);
    paths.push(["M", x, y]);
    path += `M${x},${y}`;
  }
  function handlePanMove(event) {
    event.preventDefault();
    if (!drawing) return;
    x = event.detail.x - margin;
    y =
      event.detail.y -
      document
        .getElementById("pdfpage" + selectedPageIndex)
        .getBoundingClientRect().top;
    minX = Math.min(minX, x);
    maxX = Math.max(maxX, x);
    minY = Math.min(minY, y);
    maxY = Math.max(maxY, y);
    paths.push(["L", x, y]);
    path += `L${x},${y}`;
  }
  function handlePanEnd() {
    drawing = false;
    // finish();
  }
</script>

<div
  id="drawingCanvas"
  bind:this={canvas}
  use:pannable
  on:touchstart={(e) => e.preventDefault()}
  on:touchmove={(e) => e.preventDefault()}
  on:touchend={(e) => e.preventDefault()}
  on:mousedown={(e) => e.preventDefault()}
  on:mousemove={(e) => e.preventDefault()}
  on:mouseover={(e) => e.preventDefault()}
  on:scroll={(e) => e.preventDefault()}
  on:wheel={(e) => e.preventDefault()}
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  class="relative w-full h-full select-none"
>
  <div class="fixed mr-4 mb-4 flex" style="top: 8px; right: 0px;">
    <button
      on:click={cancel}
      class=" w-24 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4
      rounded mr-4"
    >
      Cancel
    </button>
    <button
      on:click={finish}
      class="w-24 bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-4
      rounded"
    >
      Done
    </button>
  </div>
  <svg class="w-full h-full pointer-events-none">
    <path
      stroke-width="2"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="red"
      d={path}
      fill="none"
    />
  </svg>
</div>
