import axios from "axios";

export function ggID() {
  let id = 0;
  return function genId() {
    return id++;
  };
}
export function timeout(ms) {
  return new Promise((res) => setTimeout(res, ms));
}
export const noop = () => {};


export function httpAccessor(url, method, data = {}, headers={}) {
    return new Promise(async function (resolve, reject) {
        if (!!url) {
            axios({
                method, url, data, headers
            }).then(response => {
                if (!!!response) {
                    reject(new Error('Response not found'))
                }
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        } else {
            reject(new Error('URL not valid'))
        }
    });
}

export function makeGETCall(url, data, headers) {
    return httpAccessor(url, 'get', data, headers)
}

export function makePOSTCall(url, data, headers) {
    return httpAccessor(url, 'post', data, headers)
}

export function makePUTCall(url, data, headers) {
    return httpAccessor(url, 'put', data, headers)
}
