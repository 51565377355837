<script>
    import { onMount } from "svelte";
    import Drawing from "./Drawing.svelte";
    import DrawingCanvas from "./DrawingCanvas.svelte";
    import Image from "./Image.svelte";
    import PDFPage from "./PDFPage.svelte";
    import Tailwind from "./Tailwind.svelte";
    import Text from "./Text.svelte";
    import {
        readAsDataURL,
        readAsImage,
        readAsPDFFromFile,
        readAsPDFFromUrl,
    } from "./utils/asyncReader.js";
    import { ggID } from "./utils/helper.js";
    import { save } from "./utils/PDF.js";
    import prepareAssets, { fetchFont } from "./utils/prepareAssets.js";

    const genID = ggID();
    let pdfFile;
    let pdfUrl;
    let pdfName = "";
    let pages = [];
    let pagesScale = [];
    let allObjects = [];
    let currentFont = "Times-Roman";
    let currentColor = "red";
    let focusId = null;
    let selectedPageIndex = -1;
    let saving = false;
    let addingDrawing = false;

    let pdfHeight = 100;
    let pdfWidth = 100;
    let pageOffset;

    // for test purpose
    onMount(async () => {
        try {
            // const res = await fetch("/loading.pdf");
            // const pdfBlob = await res.blob();
            // await addPDF(pdfBlob);
            // selectedPageIndex = 0;

            const params = new URL(window.location.href).searchParams;
            const fileKey = params.get("fileKey");

            console.log("called onFetchPDFFromUrl");
            console.log("fileKey", fileKey);
            selectedPageIndex = -1;
            try {
                await addPDFFromUrl(
                    "https://convertias-media.s3.ap-south-1.amazonaws.com/web/" +
                        fileKey
                );
                selectedPageIndex = 0;
            } catch (e) {
                console.log(e);
            }

            setTimeout(() => {
                fetchFont(currentFont);
                prepareAssets();
            }, 5000);
            // const imgBlob = await (await fetch("/test.jpg")).blob();
            // addImage(imgBlob);
            // addTextField("測試!");
            // addDrawing(200, 100, "M30,30 L100,50 L50,70", 0.5);
        } catch (e) {
            console.log(e);
        }
    });

    async function onFetchPDFFromUrl(e) {
        const params = new URL(window.location.href).searchParams;
        const fileKey = params.get("fileKey");

        console.log("called onFetchPDFFromUrl");
        console.log("fileKey", fileKey);
        selectedPageIndex = -1;
        try {
            await addPDFFromUrl(
                "https://convertias-media.s3.ap-south-1.amazonaws.com/web/" +
                    fileKey
            );
            selectedPageIndex = 0;
        } catch (e) {
            console.log(e);
        }
    }

    async function addPDFFromUrl(url) {
        console.log("called addPDFFromUrl");
        try {
            const pdf = await readAsPDFFromUrl(url);
            // pdfName = file.name;
            // pdfFile = pdf;
            pdfUrl = url;
            const numPages = pdf.numPages;
            pages = Array(numPages)
                .fill()
                .map((_, i) => pdf.getPage(i + 1));
            allObjects = pages.map(() => []);
            pagesScale = Array(numPages).fill(1);
        } catch (e) {
            console.log("Failed to add pdf.");
            throw e;
        }
    }

    async function onUploadPDF(e) {
        const files =
            e.target.files || (e.dataTransfer && e.dataTransfer.files);
        const file = files[0];
        if (!file || file.type !== "application/pdf") return;
        selectedPageIndex = -1;
        try {
            await addPDF(file);
            selectedPageIndex = 0;
        } catch (e) {
            console.log(e);
        }
    }

    async function addPDF(file) {
        try {
            const pdf = await readAsPDFFromFile(file);
            pdfName = file.name;
            pdfFile = file;
            const numPages = pdf.numPages;
            pages = Array(numPages)
                .fill()
                .map((_, i) => pdf.getPage(i + 1));
            allObjects = pages.map(() => []);
            pagesScale = Array(numPages).fill(1);
        } catch (e) {
            console.log("Failed to add pdf.");
            throw e;
        }
    }

    async function onUploadImage(e) {
        const file = e.target.files[0];
        if (file && selectedPageIndex >= 0) {
            addImage(file);
        }
        e.target.value = null;
    }

    async function addImage(file) {
        try {
            // get dataURL to prevent canvas from tainted
            const url = await readAsDataURL(file);
            const img = await readAsImage(url);
            const id = genID();
            const { width, height } = img;
            const object = {
                id,
                type: "image",
                width,
                height,
                x: 0,
                y: 0,
                payload: img,
                file,
            };
            allObjects = allObjects.map((objects, pIndex) =>
                pIndex === selectedPageIndex ? [...objects, object] : objects
            );
        } catch (e) {
            console.log(`Fail to add image.`, e);
        }
    }

    function onAddTextField(e) {
        console.log("onAddTextField called", e);
        if (selectedPageIndex >= 0) {
            addTextField();
        }
    }

    function addTextField(lines = ["Text"]) {
        const id = genID();
        fetchFont(currentFont);
        const object = {
            id,
            text: "",
            type: "text",
            size: 16,
            width: 0, // recalculate after editing
            lineHeight: 1.4,
            // color: currentColor,
            fontFamily: currentFont,
            x: 0,
            y: 0,
            lines,
        };
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex === selectedPageIndex ? [...objects, object] : objects
        );
    }

    function onAddDrawing() {
        console.log("onAddDrawing clicked");
        if (selectedPageIndex >= 0) {
            addingDrawing = true;
        }
    }

    function addDrawing(
        originWidth,
        originHeight,
        path,
        x = 0,
        y = 0,
        scale = 1
    ) {
        const id = genID();
        const object = {
            id,
            path,
            type: "drawing",
            x: x,
            y: y,
            originWidth,
            originHeight,
            width: originWidth * scale,
            scale,
        };
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex === selectedPageIndex ? [...objects, object] : objects
        );
    }

    function addCircle() {
        addDrawing(210, 210, "M 105 5 a 100 100 0 1 0 0.0001 0");
    }

    function addTick() {
        addDrawing(260, 160, "M10,100 L50,150 L250,10");
    }

    function addCross() {
        addDrawing(250, 250, "M10,10 L240,240 M240,10 L10,240");
    }

    function selectFontFamily(event) {
        const name = event.detail.name;
        fetchFont(name);
        currentFont = name;
    }

    function selectFontColor(event) {
        const color = event.detail.name;
        // fetchFont(name);
        currentColor = color;
    }

    function selectPage(index) {
        selectedPageIndex = index;
    }

    function updateObject(objectId, payload) {
        console.log("updateObject", objectId, payload);
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex == selectedPageIndex
                ? objects.map((object) =>
                      object.id === objectId
                          ? { ...object, ...payload }
                          : object
                  )
                : objects
        );
        console.log("allObjects", allObjects);
    }

    function deleteObject(objectId) {
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex == selectedPageIndex
                ? objects.filter((object) => object.id !== objectId)
                : objects
        );
    }

    function onMeasure(scale, width, height, pageOffset, i) {
        pagesScale[i] = scale;
        pdfWidth = width;
        pdfHeight = height;
        pageOffset = pageOffset;
    }

    // FIXME: Should wait all objects finish their async work
    async function savePDF() {
        console.log("pdfFile", pdfFile);
        console.log("saving", saving);
        console.log("pages.length", pages.length);
        if (saving || !pages.length) return;
        saving = true;
        try {
            await save(pdfFile, pdfUrl, allObjects, pdfName, pagesScale);
        } catch (e) {
            console.log(e);
        } finally {
            saving = false;
        }
    }
</script>

<svelte:window
    on:dragenter|preventDefault
    on:dragover|preventDefault
    on:drop|preventDefault={onUploadPDF}
/>
<Tailwind />
<main class="flex flex-col items-center bg-gray-100 min-h-screen">
    <div
        class="fixed z-10 top-0 left-0 right-0 h-12 flex justify-center items-center bg-gray-200 border-b border-gray-300"
    >
        <div
            class="relative mr-3 flex h-8 bg-gray-400 rounded-sm overflow-hidden md:mr-4"
        >
            <label
                class="flex items-center justify-center h-full w-8 hover:bg-gray-500 cursor-pointer"
                for="text"
                class:cursor-not-allowed={selectedPageIndex < 0}
                class:bg-gray-500={selectedPageIndex < 0}
                on:click={onAddTextField}
            >
                <img src="notes.svg" alt="An icon for adding text" />
            </label>
            <label
                class="flex items-center justify-center h-full w-8 hover:bg-gray-500 cursor-pointer"
                on:click={onAddDrawing}
                class:cursor-not-allowed={selectedPageIndex < 0}
                class:bg-gray-500={selectedPageIndex < 0}
            >
                <img src="gesture.svg" alt="An icon for adding drawing" />
            </label>
            <label
                class="flex items-center justify-center h-full w-8 hover:bg-gray-500 cursor-pointer"
                on:click={addCircle}
                class:cursor-not-allowed={selectedPageIndex < 0}
                class:bg-gray-500={selectedPageIndex < 0}
            >
                <img src="circle.svg" alt="An icon for adding circle" />
            </label>
            <label
                class="flex items-center justify-center h-full w-8 hover:bg-gray-500 cursor-pointer"
                on:click={addTick}
                class:cursor-not-allowed={selectedPageIndex < 0}
                class:bg-gray-500={selectedPageIndex < 0}
            >
                <img src="tick.svg" alt="An icon for adding tick" />
            </label>
            <label
                class="flex items-center justify-center h-full w-8 hover:bg-gray-500 cursor-pointer"
                on:click={addCross}
                class:cursor-not-allowed={selectedPageIndex < 0}
                class:bg-gray-500={selectedPageIndex < 0}
            >
                <img src="cross.svg" alt="An icon for adding cross" />
            </label>
        </div>
        <button
            on:click={savePDF}
            class="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
            class:cursor-not-allowed={pages.length === 0 || saving}
            class:bg-blue-700={pages.length === 0 || saving}
        >
            {saving ? "Saving" : "Save"}
        </button>
    </div>
    {#if addingDrawing}
        <div
            class="fixed z-10 left-0 right-0 border-b border-gray-300 shadow-lg"
            style="height: {pdfHeight}px; width: {pdfWidth}px; margin: auto; top: {document
                .getElementById('pdfpage' + selectedPageIndex)
                .getBoundingClientRect().top}px"
        >
            <DrawingCanvas
                on:finish={(e) => {
                    const { x, y, originWidth, originHeight, path } = e.detail;
                    let scale = 1;
                    if (originWidth > pdfWidth) {
                        scale = pdfWidth / originWidth;
                    }
                    addDrawing(originWidth, originHeight, path, x, y, scale);
                    addingDrawing = false;
                }}
                on:cancel={() => (addingDrawing = false)}
                {pdfWidth}
                {pdfHeight}
                {selectedPageIndex}
            />
        </div>
    {/if}
    {#if pages.length}
        <div class="w-full">
            {#each pages as page, pIndex (page)}
                <div
                    class="p-5 w-full flex flex-col items-center overflow-hidden"
                    style="position: relative; top: 30px; overflow-x: hidden; overflow-y: hidden"
                    on:mousedown={() => {
                        if (!addingDrawing) selectPage(pIndex);
                    }}
                    on:touchstart={() => {
                        if (!addingDrawing) selectPage(pIndex);
                    }}
                >
                    <div
                        class="relative shadow-lg"
                        class:shadow-outline={pIndex === selectedPageIndex}
                    >
                        <PDFPage
                            on:measure={(e) =>
                                onMeasure(
                                    e.detail.scale,
                                    e.detail.width,
                                    e.detail.height,
                                    e.detail.pageOffset,
                                    pIndex
                                )}
                            {page}
                            {pIndex}
                        />
                        <div
                            class="absolute top-0 left-0 transform origin-top-left"
                            style="transform: scale({pagesScale[
                                pIndex
                            ]}); touch-action: none;"
                        >
                            {#each allObjects[pIndex] as object (object.id)}
                                {#if object.type === "image"}
                                    <Image
                                        on:update={(e) =>
                                            updateObject(object.id, e.detail)}
                                        on:delete={() =>
                                            deleteObject(object.id)}
                                        file={object.file}
                                        payload={object.payload}
                                        x={object.x}
                                        y={object.y}
                                        width={object.width}
                                        height={object.height}
                                        pageScale={pagesScale[pIndex]}
                                    />
                                {:else if object.type === "text"}
                                    <Text
                                        on:update={(e) =>
                                            updateObject(object.id, e.detail)}
                                        on:delete={() =>
                                            deleteObject(object.id)}
                                        on:selectFont={selectFontFamily}
                                        on:selectColor={selectFontColor}
                                        text={object.lines}
                                        x={object.x}
                                        y={object.y}
                                        size={object.size}
                                        color={object.color}
                                        lineHeight={object.lineHeight}
                                        fontFamily={object.fontFamily}
                                        pageScale={pagesScale[pIndex]}
                                    />
                                {:else if object.type === "drawing"}
                                    <Drawing
                                        on:update={(e) =>
                                            updateObject(object.id, e.detail)}
                                        on:delete={() =>
                                            deleteObject(object.id)}
                                        path={object.path}
                                        x={object.x}
                                        y={object.y}
                                        width={object.width}
                                        originWidth={object.originWidth}
                                        originHeight={object.originHeight}
                                        pageScale={pagesScale[pIndex]}
                                    />
                                {/if}
                            {/each}
                        </div>
                    </div>
                </div>
            {/each}
        </div>
    {:else}
        <div class="w-full flex-grow flex justify-center items-center">
            <span class=" font-bold text-3xl text-gray-500"
                >Please wait while we load PDF...</span
            >
        </div>
    {/if}
</main>
