<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  export let page;
  const dispatch = createEventDispatcher();
  let canvas;
  let width;
  let height;
  export let pIndex;
  let clientWidth;
  let mounted;
  function measure() {
    dispatch("measure", {
      scale: canvas.clientWidth / width,
      width: width,
      height: height,
      pageOffset: document.getElementById("pdfpage" + pIndex).offsetTop,
    });
  }
  async function render() {
    const _page = await page;
    const context = canvas.getContext("2d");
    const viewport = _page.getViewport({ scale: 1, rotation: 0 });
    width = viewport.width;
    height = viewport.height;
    await _page.render({
      canvasContext: context,
      viewport,
    }).promise;
    measure();
    window.addEventListener("resize", measure);
  }
  onMount(render);
  onDestroy(() => {
    window.removeEventListener("resize", measure);
  });
</script>

<div
  on:touchstart={(e) => e.preventDefault()}
  on:touchmove={(e) => e.preventDefault()}
  on:touchend={(e) => e.preventDefault()}
  on:mousedown={(e) => e.preventDefault()}
  on:mousemove={(e) => e.preventDefault()}
  on:mouseover={(e) => e.preventDefault()}
  on:scroll={(e) => e.preventDefault()}
  on:wheel={(e) => e.preventDefault()}
>
  <canvas
    id={"pdfpage" + pIndex}
    bind:this={canvas}
    class="max-w-full"
    style="width: {width}px;"
    {width}
    {height}
  />
</div>
